import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { Dispatch, useEffect, useReducer } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import strings from "../common/strings";
import useUid from "../common/useUid";
import { firestore } from "../firebaseApp";

function useWeeklyEmailDisabled(): [boolean, Dispatch<boolean>, boolean] {
  const [uid, loadingUid] = useUid(false);
  const [
    { weeklyEmailDisabled } = { weeklyEmailDisabled: false },
    loadingPhoneNumber,
  ] = useDocumentData(firestore.collection("users").doc(uid));

  const [{ promise = undefined, optimisticValue = undefined } = {}, dispatch] =
    useReducer(
      (
        prevState:
          | { promise: Promise<unknown>; optimisticValue: boolean }
          | undefined,
        action: boolean | "COMPLETE"
      ) => {
        if (action === "COMPLETE") return undefined;
        else if (uid === undefined) return prevState;
        else {
          const update = () =>
            firestore.collection("users").doc(uid).set(
              {
                weeklyEmailDisabled: action,
              },
              { merge: true }
            );

          return {
            promise: prevState?.promise?.then(update) ?? update(),
            optimisticValue: action,
          };
        }
      },
      undefined
    );

  useEffect(() => {
    if (promise !== undefined) {
      let cancelled = false;

      promise.finally(() => {
        if (!cancelled) dispatch("COMPLETE");
      });

      return () => {
        cancelled = true;
      };
    }
  }, [promise]);

  return [
    optimisticValue ?? weeklyEmailDisabled,
    dispatch,
    loadingUid || loadingPhoneNumber || promise !== undefined,
  ];
}

export default function WeeklyEmailSection() {
  const [weeklyEmailDisabled, setWeeklyEmailDisabled, loading] =
    useWeeklyEmailDisabled();

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h2">
          {strings.weeklyEmail}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                onChange={() => setWeeklyEmailDisabled(!weeklyEmailDisabled)}
                checked={!weeklyEmailDisabled}
                disabled={loading}
              />
            }
            label={strings.receiveWeeklyEmail}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}
